<template>
    <div>
        <Navigation />
        <!-- 企业简介 -->
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI企业简介" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">企业名称：</p>
                            <div class="upload_box">
                                <el-input v-model="form.name" placeholder="请录入企业名称"></el-input>
                            </div>

                        </div>
                        <ai_select selectTitle="从事行业" :select_list="selectList" @getSelectVal="get_type" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">业务描述：</p>
                            <div class="upload_box">
                                <el-input v-model="form.desc" placeholder="请录入您公司对外业务"></el-input>
                            </div>

                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">其他描述：</p>
                            <div class="upload_box">
                                <el-input maxlength="50" v-model="form.qtherDesc" placeholder="如：成立时间、所获荣誉等"></el-input>
                            </div>
                            <!-- <p>{{ form.qtherDesc.length }}/50</p> -->
                        </div>
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import ai_title from '@/components/ai_title.vue'
import ai_select from '@/components/ai_select.vue'
import wordNav from '@/components/wordNav.vue'
import ai_text_content from '@/components/ai_text_content'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
    mounted() {
        this.getUserInfo()
    },
    components: {
        ai_title,
        ai_select,
        wordNav,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            name: '企业管理',
            form: {
                type: '', //从事行业
                name: '', //企业名称
                desc: '', //主营业务
                qtherDesc: '' // 关键字
            },
            selectList: [
                '互联网/AI',
                '电子/电器/通信',
                '产品',
                '运营/客服',
                '销售',
                '人力/行政/法务',
                '财务/审计/税务',
                '生产制造',
                '生活服务/零售',
                '餐饮',
                '酒店/旅游',
                '教育培训',
                '设计',
                '房地产/建筑',
                '影视传媒',
                '市场/公关/广告',
                '物流/仓储/司机',
                '采购/贸易',
                '汽车',
                '医疗器械',
                '金融',
            ],
            contentData: {}, //内容
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        get_type(val) {
            this.form.type = val
        },

        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入主营业务',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            // if (this.form.kesWord == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true

                // this.postData()
            }
        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            this.curlPost('/file/company_profile', {
                name: this.form.name,
                type: this.form.type,
                // business: this.form.business,
                desc: this.form.desc,
                qtherDesc: this.form.qtherDesc,
                // kesWord: this.form.kesWord,
                mobile: this.$user_info.mobile,

            }).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>